import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getContentsByCustomerId } from "../../api/contents";
import { getFirstChar, renderMonth } from "../../helpers/common";

const CertificationsPreview = () => {
  const { candidateId } = useParams();
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    getContentsByCustomerId(`candidate_certifications`, candidateId)
      .then((res) => {
        setCertifications(res.data);
      })
      .catch((err) => {
        console.log("err::", err);
      });
  }, [candidateId]);

  const getValidity = (item) => {
    const issueMonth = item?.additional_fields?.issue_month;
    const issueYear = item?.additional_fields?.issue_year;

    const expirationMonth = item?.additional_fields?.expiration_month;
    const expirationYear = item?.additional_fields?.expiration_year;

    if (!expirationMonth) {
      return `Validity from: ${renderMonth(
        issueMonth
      )} ${issueYear}. Does not expire`;
    }

    return `Validity: (${renderMonth(issueMonth)} ${issueYear} - ${renderMonth(
      expirationMonth
    )} ${expirationYear})`;
  };

  if (!certifications?.length) {
    return null;
  }

  return (
    <div className='candidate-education-details mt-4'>
      <h6 className='fs-18 fw-bold mb-0'>Certifications</h6>

      {certifications?.map((item) => {
        return (
          <div
            key={item?.id}
            className='candidate-education-content mt-4 d-flex'
          >
            <div className='circle flex-shrink-0 bg-soft-primary'>
              {getFirstChar(item?.additional_fields?.title)}
            </div>
            <div className='ms-4'>
              <div className='d-flex'>
                <h6 className='fs-16 mb-1 me-3'>
                  {item?.additional_fields?.title}
                </h6>
              </div>
              <p className='mb-2 text-muted'>{getValidity(item)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CertificationsPreview;
