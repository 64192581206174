import React from "react";
import { useSelector } from "react-redux";
import { getFirstChar, renderMonth } from "../../../../../helpers/common";

const Certifications = ({ handleOpenModal, componentType = "overview" }) => {
  const { certifications } = useSelector((state) => state.customer);

  const getValidity = (item) => {
    const issueMonth = item?.additional_fields?.issue_month;
    const issueYear = item?.additional_fields?.issue_year;

    const expirationMonth = item?.additional_fields?.expiration_month;
    const expirationYear = item?.additional_fields?.expiration_year;

    if (!expirationMonth) {
      return `Validity from: ${renderMonth(
        issueMonth
      )} ${issueYear}. Does not expire`;
    }

    return `Validity: (${renderMonth(issueMonth)} ${issueYear} - ${renderMonth(
      expirationMonth
    )} ${expirationYear})`;
  };

  if (!certifications?.length) {
    return <p className='mt-4 mb-5'>No certification has been added.</p>;
  }

  return (
    <>
      {certifications?.map((item) => {
        return (
          <div
            key={item?.id}
            className='candidate-education-content mt-4 d-flex'
          >
            <div className='circle flex-shrink-0 bg-soft-primary'>
              {getFirstChar(item?.additional_fields?.title)}
            </div>
            <div className='ms-4'>
              <div className='d-flex'>
                <h6 className='fs-16 mb-1 me-3'>
                  {item?.additional_fields?.title}
                </h6>

                {componentType === "update" ? (
                  <div
                    className='cursor-pointer'
                    onClick={() => handleOpenModal("update", item)}
                  >
                    <i className='uil uil-edit'></i>
                  </div>
                ) : null}
              </div>
              <p className='mb-2 text-muted'>{getValidity(item)}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Certifications;
