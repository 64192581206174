import React from "react";
import { toArray } from "../../../helpers/common";
import MultiSelector from "../../../components/MultiSelector";

const SkillsForm = ({ formData, setFormData }) => {
  const maxAllowedSkills = 15;

  const handleSkillsChange = (updatedSkills) => {
    setFormData({ ...formData, skills: updatedSkills });
  };

  return (
    <div className='mb-4'>
      <h5 className='mt-3 mb-2 d-inline-block'>Skills</h5>
      <p style={{ fontSize: 14 }}>
        Add your skills below, separating each skill with a comma for clarity.
      </p>

      <MultiSelector
        initialItems={toArray(formData?.skills)}
        onChange={handleSkillsChange}
        maxItems={maxAllowedSkills}
        placeholder='Eg. JavaScript, React, Node.js'
        disableOnMaxItems={true}
      />
    </div>
  );
};

export default SkillsForm;
