import React, { useState } from "react";
import { Button } from "reactstrap";
import CertificationModal from "../../Account/Candidate/MyProfile/Certifications/CertificationModal";
import Certifications from "../../Account/Candidate/MyProfile/Certifications";
import { useSelector } from "react-redux";

const CertificationForm = () => {
  const { certifications } = useSelector((state) => state.customer);

  // Certification states
  const [openCertificationModal, setOpenCertificationModal] = useState(false);
  const [certificationModalType, setCertificationModalType] = useState("add");
  const [certificationData, setCertificationData] = useState(null);

  // Certification modal handle
  const handleOpenCertificationModal = (type, data = null) => {
    setOpenCertificationModal(true);
    setCertificationModalType(type);
    setCertificationData(data);
  };

  return (
    <div style={{ minHeight: 300 }}>
      <h5 className='mt-3 mb-2 d-inline-block'>Certifications</h5>

      {certifications?.length > 0 ? (
        <div>
          <p style={{ fontSize: 14 }}>
            Display your key certifications for academic and professional
            acknowledgment.
          </p>

          <Certifications
            handleOpenModal={handleOpenCertificationModal}
            componentType='update'
          />
        </div>
      ) : (
        <p className='mt-3'>
          Summarize your certifications by starting with your most recent
          achievements or education and then list earlier academic and
          professional milestones.
        </p>
      )}

      <Button
        color='primary'
        onClick={() => handleOpenCertificationModal("add")}
        outline
        size='sm'
        className='mt-3 mb-3'
      >
        Add Certification
      </Button>

      {openCertificationModal && (
        <CertificationModal
          openCertificationModal={openCertificationModal}
          setOpenCertificationModal={setOpenCertificationModal}
          modalType={certificationModalType}
          certificationData={certificationData}
        />
      )}
    </div>
  );
};

export default CertificationForm;
