import React from "react";
import MultiSelector from "../../../components/MultiSelector";
import { toArray } from "../../../helpers/common";

const OthersForm = ({ formData, setFormData }) => {
  const handleSelect = (updatedSkills, name) => {
    setFormData({ ...formData, [name]: updatedSkills });
  };

  return (
    <div className='mb-4'>
      <h5 className='mt-3 mb-2 d-inline-block'>Others</h5>
      <p style={{ fontSize: 14, marginBottom: 20 }}>
        Provide details and updates about additional skills, interests, and
        other relevant information
      </p>

      <MultiSelector
        initialItems={toArray(formData?.hobbies)}
        onChange={(values) => handleSelect(values, "hobbies")}
        maxItems={5}
        placeholder='Eg. Reading, Painting, Hiking'
        label='Hobbies'
      />

      <MultiSelector
        initialItems={toArray(formData?.languages_known)}
        onChange={(values) => handleSelect(values, "languages_known")}
        maxItems={20}
        placeholder='Eg. English, Hindi, Urdu'
        label='Languages Known'
      />
    </div>
  );
};

export default OthersForm;
