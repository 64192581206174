import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  Input,
  Label,
  Col,
  Row,
  FormFeedback,
  Spinner,
  FormGroup,
} from "reactstrap";
import { getCandidateCertifications } from "../../../../../actions/customer";
import { createContent, updateContentsById } from "../../../../../api/contents";
import { CustomSnackbar } from "../../../../../helpers/CustomSnackbar";
import YearOptions from "../../../../../helpers/YearOptions";
import MonthOptions from "../../../../../helpers/MonthOptions";

const CertificationModal = ({
  setOpenCertificationModal,
  openCertificationModal,
  modalType = "add",
  certificationData,
}) => {
  const additional_fields = certificationData?.additional_fields;

  const initialState = {
    title: "",
    issue_month: "",
    issue_year: "",
    expiration_month: "",
    expiration_year: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [hasExpiration, setHasExpiration] = useState(false);

  const triggerModal = () => setOpenCertificationModal(!openCertificationModal);

  const { profile } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const onChangeValue = (e, value) => {
    setFormData({ ...formData, [value]: e.target.value });
  };

  const toggleExpiration = (e) => {
    setHasExpiration(e.target.checked);
    setFormData({ ...formData, expiration_month: "", expiration_year: "" });
    setErrors(null);
  };

  useEffect(() => {
    setFormData({
      title: additional_fields?.title || "",
      issue_month: additional_fields?.issue_month || "",
      issue_year: additional_fields?.issue_year || "",
      expiration_month: additional_fields?.expiration_month || "",
      expiration_year: additional_fields?.expiration_year || "",
    });

    if (additional_fields?.expiration_month) {
      setHasExpiration(true);
    }

  }, [certificationData, additional_fields]);

  const onCertificationSave = (e) => {
    e.preventDefault();

    if (!formData?.title) {
      setErrors({ title: "This is required field" });
      return;
    }

    if (!formData?.issue_month) {
      setErrors({ issue_month: "This is required field" });
      return;
    }

    if (!formData?.issue_year) {
      setErrors({ issue_year: "This is required field" });
      return;
    }

    if (hasExpiration) {
      if (!formData?.expiration_month) {
        setErrors({ expiration_month: "This is required field" });
        return;
      }

      if (!formData?.expiration_year) {
        setErrors({ expiration_year: "This is required field" });
        return;
      }

      // Validate that the expiration date is not before the issue date
      const issueDate = new Date(formData.issue_year, formData.issue_month - 1);
      const expirationDate = new Date(
        formData.expiration_year,
        formData.expiration_month - 1
      );

      if (expirationDate <= issueDate) {
        setErrors({
          expiration_date:
            "The expiration date cannot be the same as or before the issue date",
        });
        return;
      }
    }

    const payload = {
      type: "candidate_certifications",
      AdditionalFields: {
        ...formData,
        candidate_id: profile?.id?.toString(),
      },
    };

    console.log("payload::", payload);
    setErrors(null);


    setLoading(true);

    if (modalType === "add") {
      createContent(payload)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.id) {
            triggerModal();
            CustomSnackbar.success("Certification added successfully.");
            setFormData(initialState);

            dispatch(getCandidateCertifications(profile.id));
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err?.message);
          }
        })
        .finally(() => setLoading(false));
    } else {
      updateContentsById(certificationData?.id, payload)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.id) {
            triggerModal();
            CustomSnackbar.success("Certification updated successfully.");
            setFormData(initialState);

            dispatch(getCandidateCertifications(profile.id));
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div
        className='modal fade'
        id='applyNow'
        tabIndex='-1'
        aria-labelledby='applyNow'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <Modal isOpen={openCertificationModal} toggle={triggerModal} centered>
            <ModalBody className='modal-body p-4'>
              <div className='text-center mb-4'>
                <h5 className='modal-title' id='staticBackdropLabel'>
                  {modalType === "add" ? "Add" : "Update"} Certification
                </h5>
              </div>
              <div className='position-absolute end-0 top-0 p-3'>
                <button
                  type='button'
                  onClick={triggerModal}
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>

              <form onSubmit={onCertificationSave}>
                <div className='mb-3'>
                  <Label for='certificationControlInput' className='form-label'>
                    Certification Name *
                  </Label>
                  <Input
                    type='text'
                    className='form-control'
                    id='certificationControlInput'
                    placeholder='Enter certification name'
                    value={formData.title}
                    onChange={(e) => onChangeValue(e, "title")}
                    invalid={errors?.title ? true : false}
                  />
                  <FormFeedback>{errors?.title}</FormFeedback>
                </div>

                <div className='mb-3'>
                  <Label for='select' className='form-label'>
                    Issued Month and Year *
                  </Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type='select'
                        className='form-select'
                        name='issued-month'
                        id='issued-month'
                        aria-label='Select issued month'
                        value={formData?.issue_month}
                        onChange={(e) => onChangeValue(e, "issue_month")}
                        invalid={errors?.issue_month ? true : false}
                      >
                        <MonthOptions />
                      </Input>
                      <FormFeedback>{errors?.issue_month}</FormFeedback>
                    </Col>

                    <Col md={6}>
                      <Input
                        type='select'
                        className='form-select'
                        name='issued-year'
                        id='issued-year'
                        aria-label='Select issued year'
                        value={formData?.issue_year}
                        onChange={(e) => onChangeValue(e, "issue_year")}
                        invalid={errors?.issue_year ? true : false}
                      >
                        <YearOptions />
                      </Input>
                      <FormFeedback>{errors?.issue_year}</FormFeedback>
                    </Col>
                  </Row>
                </div>

                <div className='mb-3'>
                  <Label for='expirationCheckbox' className='form-label'>
                    Expiration Date
                  </Label>
                  <Row>
                    <Col md={12}>
                      <FormGroup check inline>
                        <Input
                          type='checkbox'
                          id='expiration-checkbox'
                          onChange={toggleExpiration}
                          checked={hasExpiration}
                        />
                        <Label check htmlFor='expiration-checkbox'>
                          <small>This certification expires</small>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  {hasExpiration && (
                    <Row className='mt-3'>
                      <Col md={6}>
                        <Input
                          type='select'
                          className='form-select'
                          name='expiration-month'
                          id='expiration-month'
                          aria-label='Select expiration month'
                          value={formData?.expiration_month}
                          onChange={(e) => onChangeValue(e, "expiration_month")}
                          invalid={errors?.expiration_month ? true : false}
                        >
                          <MonthOptions />
                        </Input>
                        <FormFeedback>{errors?.expiration_month}</FormFeedback>
                      </Col>

                      <Col md={6}>
                        <Input
                          type='select'
                          className='form-select'
                          name='expiration-year'
                          id='expiration-year'
                          aria-label='Select expiration year'
                          value={formData?.expiration_year}
                          onChange={(e) => onChangeValue(e, "expiration_year")}
                          invalid={errors?.expiration_year ? true : false}
                        >
                          <YearOptions />
                        </Input>
                        <FormFeedback>{errors?.expiration_year}</FormFeedback>
                      </Col>
                    </Row>
                  )}

                  {errors?.expiration_date && (
                    <p className='invalid-feedback d-block'>
                      {errors?.expiration_date}
                    </p>
                  )}
                </div>

                <div className='text-end'>
                  <button
                    type='button'
                    onClick={triggerModal}
                    className='btn btn-danger me-2 '
                  >
                    Cancel
                  </button>

                  {loading ? (
                    <button type='button' className='btn btn-primary disabled'>
                      Save <Spinner size='sm' />
                    </button>
                  ) : (
                    <button type='submit' className='btn btn-primary'>
                      Save
                    </button>
                  )}
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </div>

      {/* <ConfirmationModal
        title="Delete certification"
        message="Are you sure you want to delete this certification ?"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
      /> */}
    </>
  );
};

export default CertificationModal;
