import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { Icon } from "@iconify/react";

const MultiSelector = ({
  initialItems,
  onChange,
  maxItems = 10,
  placeholder,
  label,
  disableOnMaxItems,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [items, setItems] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    setItems(initialItems?.slice(0, maxItems));
  }, [initialItems, maxItems]);

  const isLimitReached = items.length >= maxItems;

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "," || event.key === "Enter") {
      event.preventDefault();
      addItem(inputValue);
    }
  };

  const handleOnBlur = () => {
    addItem(inputValue);
  };

  const addItem = (input) => {
    const trimmedInput = input.trim();

    if (trimmedInput && isLimitReached) {
      setError(`You can add maximum ${maxItems} ${label?.toLowerCase() || 'items'}.`);
      return;
    }

    if (trimmedInput) {
      const newItems = trimmedInput.split(",").map((item) => item.trim());
      const uniqueNewItems = newItems.filter((item) => !items.includes(item));

      const updatedItems = [...items, ...uniqueNewItems].slice(0, maxItems);

      setItems(updatedItems);
      setInputValue("");
      onChange(updatedItems.join(", "));
    }
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    onChange(updatedItems.join(", "));
    setError("");
  };

  const showError = !disableOnMaxItems && error;

  return (
    <div>
      {label && <p className='mb-0 pb-2'>{label}</p>}
      <div
        className='multi-selector'
        style={{ borderColor: showError && "#ce3442" }}
      >
        {items.length > 0 && (
          <div className='pill-container'>
            {items.map((item, index) => (
              <span key={index} className='pill-btn'>
                {item}
                <div className='icon' onClick={() => removeItem(index)}>
                  <Icon icon={"charm:cross"} color='#606060' fontSize={14} />
                </div>
              </span>
            ))}
          </div>
        )}

        <Input
          type='text'
          className='form-control'
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onBlur={handleOnBlur}
          readOnly={disableOnMaxItems ? isLimitReached : false}
        />
      </div>

      {showError && (
        <p style={{ fontSize: 12, color: "#ce3442", marginTop: -8 }}>{error}</p>
      )}

      {!label && (
        <p style={{ fontSize: 12, color: "#606060", marginTop: -8 }}>
          Note: You can add maximum {maxItems} items.
        </p>
      )}
    </div>
  );
};

MultiSelector.propTypes = {
  initialItems: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  maxItems: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disableOnMaxItems: PropTypes.bool,
};

export default MultiSelector;
