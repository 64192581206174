import {
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  FORGET_PASSWORD_SUCCESS,
  GET_CUSTOMER_ADDRESS_SUCCESS,
  GET_CUSTOMER_ADDRESS_FAILURE,
  GET_CUSTOMER_ADDRESS_LOADING,
  GET_CANDIDATE_QUALIFICATIONS_SUCCESS,
  GET_CANDIDATE_QUALIFICATIONS_FAILURE,
  GET_CANDIDATE_EXPERIENCES_SUCCESS,
  GET_CANDIDATE_EXPERIENCES_FAILURE,
  GET_CANDIDATE_CERTIFICATIONS_SUCCESS,
  GET_CANDIDATE_CERTIFICATIONS_FAILURE,
} from "../constants/actionTypes";
import {
  authKey_local_storage_key,
  customerId_local_storage_key,
  userInfo_local_storage_key,
} from "../helpers/common";

const INITIAL_STATE = {
  profile: {
    verified: false,
    cart: [],
  },
  address: [],
  loading: true,
  qualifications: [],
  experiences: [],
  errors: null,
  certifications: [],
};

export default function customer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        // why we are setting user info when the authtoken is not coming
        // profile: { ...state.profile, ...action.payload.data },
        errors: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        errors: action.payload.response.data?.[0]?.message,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem(authKey_local_storage_key, action.payload.token);
      localStorage.setItem(
        customerId_local_storage_key,
        action.payload.customer.id
      );
      localStorage.setItem(
        userInfo_local_storage_key,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        profile: {
          ...action.payload.customer,
          cart:
            action.payload.customer.cart_data !== ""
              ? JSON.parse(action.payload.customer.cart_data)
              : [],
          verified: true,
        },
      };
    case GET_CUSTOMER_SUCCESS:
      return { ...state, profile: action.payload.data };
    case LOGIN_FAILURE:
      return {
        ...state,
        errors: action.payload.error,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem(authKey_local_storage_key);
      localStorage.removeItem(customerId_local_storage_key);
      localStorage.removeItem(userInfo_local_storage_key);

      return {
        ...state,
        profile: { verified: false },
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        profile: { verified: false },
        errors: action.payload.message,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: { ...action.payload.data, verified: true },
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload.data },
      };
    case GET_CANDIDATE_QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        qualifications: action.payload.data,
      };
    case GET_CANDIDATE_QUALIFICATIONS_FAILURE:
      return {
        ...state,
        qualifications: [],
      };
    case GET_CANDIDATE_EXPERIENCES_SUCCESS:
      return {
        ...state,
        experiences: action.payload.data,
      };
    case GET_CANDIDATE_EXPERIENCES_FAILURE:
      return {
        ...state,
        experiences: [],
      };
    case GET_CANDIDATE_CERTIFICATIONS_SUCCESS:
      return {
        ...state,
        certifications: action.payload.data,
      };
    case GET_CANDIDATE_CERTIFICATIONS_FAILURE:
      return {
        ...state,
        certifications: [],
      };
    case GET_CUSTOMER_ADDRESS_LOADING:
      return { ...state, loading: true, address: [] };
    case GET_CUSTOMER_ADDRESS_SUCCESS:
      return { ...state, loading: false, address: action.payload.data };
    case GET_CUSTOMER_ADDRESS_FAILURE:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}
