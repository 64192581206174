import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import parse from "html-react-parser";
import QualificationJourney from "./QualificationJourney";
import ExperienceJourney from "./ExperienceJourney";
import CertificationsPreview from "./CertificationsPreview";

const RightSideContent = ({ additional_fields }) => {
  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className='profile-content-page mt-4 mt-lg-0'>
          <CardBody className='p-4'>
            <div>
              <h5 className='fs-18 fw-bold'>About</h5>
              <p className='text-muted mt-4'>
                {additional_fields?.details
                  ? parse(
                      additional_fields?.details?.replaceAll("\n", "<br/>") ??
                        ""
                    )
                  : "No details available."}
                {}
              </p>
            </div>
            <div className='candidate-education-details mt-4'>
              <h6 className='fs-18 fw-bold mb-0'>Qualifications</h6>

              <QualificationJourney />
            </div>

            <div className='candidate-education-details mt-4'>
              <h6 className='fs-18 fw-bold mb-0'>Experiences</h6>

              <ExperienceJourney />
            </div>

            <CertificationsPreview />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
