import { createAction } from "redux-actions";
import { getContentsByCustomerId } from "../api/contents";
import * as Auth from "../api/customer";
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_SUCCESS,
  GET_CANDIDATE_QUALIFICATIONS_SUCCESS,
  GET_CANDIDATE_QUALIFICATIONS_FAILURE,
  GET_CANDIDATE_EXPERIENCES_SUCCESS,
  GET_CANDIDATE_EXPERIENCES_FAILURE,
  CLEAR_NOTIFICATIONS,
  GET_CANDIDATE_CERTIFICATIONS_SUCCESS,
  GET_CANDIDATE_CERTIFICATIONS_FAILURE,
} from "../constants/actionTypes";
import { apiErrorHandler, isEmailVerified } from "../helpers/common";
import { CustomSnackbar } from "../helpers/CustomSnackbar";

const signUpSuccess = createAction(SIGNUP_SUCCESS);
const signUpFailure = createAction(SIGNUP_FAILURE);

export function signUp(params) {
  return (dispatch) =>
    Auth.signUp(params)
      .then((json) => {
        dispatch(signUpSuccess(json));
        console.log("signUp-json::", json);
        return true;
      })
      .catch((error) => {
        dispatch(signUpFailure(error));
        CustomSnackbar.error(error?.response?.data[0]?.message);
        console.log("signUp-error::", error);
        return false;
      });
}

const loginSuccess = createAction(LOGIN_SUCCESS);
const loginFailure = createAction(LOGIN_FAILURE);

export function login(params) {
  return (dispatch) =>
    Auth.loginUsingEmail(params)
      .then((json) => {
        // Prevent users from logging into their accounts if their email is not verified.
        if (!isEmailVerified(json?.data)) {
          return json;
        }

        if (json?.data?.customer?.customer_type === params?.customer_type) {
          setTimeout(() => {
            CustomSnackbar.success("Login Successfully");
          }, 100);
          dispatch(loginSuccess(json.data));
        } else {
          CustomSnackbar.warning("Please select correct customer type");
        }
        return json;
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          CustomSnackbar.error(error?.response?.data?.message);
        } else if (error?.message) {
          CustomSnackbar.error(error?.message);
        } else {
          CustomSnackbar.error("Something went wrong! Please try again later.");
        }
        console.log("login-error::", error);
        dispatch(loginFailure({ error: "Invalid username or password" }));
        return false;
      });
}

const updateProfileSuccess = createAction(UPDATE_PROFILE_SUCCESS);
const updateProfileFailure = createAction(UPDATE_PROFILE_FAILURE);

export function updateProfile(params) {
  return (dispatch) =>
    Auth.updateProfile(params)
      .then((json) => {
        dispatch(updateProfileSuccess(json));
        return json;
      })
      .catch((error) => {
        apiErrorHandler(error);
        console.log("update-pro-error::", error);
        dispatch(updateProfileFailure(error));
        return error;
      });
}

const getProfileSuccess = createAction(GET_CUSTOMER_SUCCESS);
const getProfileFailure = createAction(GET_CUSTOMER_FAILURE);

export function getCustomerDetails(userId) {
  return (dispatch) =>
    Auth.getCustomerDetails(userId)
      .then((json) => {
        dispatch(getProfileSuccess(json));
        return json;
      })
      .catch((error) => {
        getProfileFailure(error);
        return error;
      });
}

const getCandidateQualificationsSuccess = createAction(
  GET_CANDIDATE_QUALIFICATIONS_SUCCESS
);
const getCandidateQualificationsFailure = createAction(
  GET_CANDIDATE_QUALIFICATIONS_FAILURE
);

export function getCandidateQualifications(userId) {
  return (dispatch) =>
    getContentsByCustomerId("candidate_qualifications", userId)
      .then((json) => {
        dispatch(getCandidateQualificationsSuccess(json));
        return json;
      })
      .catch((error) => {
        getCandidateQualificationsFailure(error);
        return error;
      });
}

const getCandidateExperiencesSuccess = createAction(
  GET_CANDIDATE_EXPERIENCES_SUCCESS
);
const getCandidateExperiencesFailure = createAction(
  GET_CANDIDATE_EXPERIENCES_FAILURE
);

export function getCandidateExperiences(userId) {
  return (dispatch) =>
    getContentsByCustomerId("candidate_experiences", userId)
      .then((json) => {
        dispatch(getCandidateExperiencesSuccess(json));
        return json;
      })
      .catch((error) => {
        getCandidateExperiencesFailure(error);
        return error;
      });
}

const getCandidateCertificationSuccess = createAction(
  GET_CANDIDATE_CERTIFICATIONS_SUCCESS
);
const getCandidateCertificationFailure = createAction(
  GET_CANDIDATE_CERTIFICATIONS_FAILURE
);

export function getCandidateCertifications(userId) {
  return (dispatch) =>
    getContentsByCustomerId("candidate_certifications", userId)
      .then((json) => {
        dispatch(getCandidateCertificationSuccess(json));
        return json;
      })
      .catch((error) => {
        getCandidateCertificationFailure(error);
        return error;
      });
}

const logoutSuccess = createAction(LOGOUT_SUCCESS);
const clearNotifications = createAction(CLEAR_NOTIFICATIONS);

export function logout(showSnackbar = true) {
  return (dispatch) => {
    dispatch(logoutSuccess());
    dispatch(clearNotifications());
    if (showSnackbar) {
      CustomSnackbar.success("Logout Successfully.");
    }
  };
}

const forgetPasswordSuccess = createAction(FORGET_PASSWORD_SUCCESS);
const forgetPasswordFailure = createAction(FORGET_PASSWORD_FAILURE);

export function forgetPassword(params) {
  return (dispatch) =>
    Auth.forgetPassword(params)
      .then((json) => {
        dispatch(forgetPasswordSuccess(json));
        // dispatch(showSuccessSnackbar(`An OTP sent to your mobile number +${params.mobile_number}`));
        return true;
      })
      .catch((error) => {
        // dispatch(showErrorSnackbar('The mobile number may be wrong!'))
        dispatch(forgetPasswordFailure(error));
        return false;
      });
}

export const getErrorMsg = (err) => {
  let msg = "Something went wrong! Please try again later!";
  if (
    err.response &&
    err.response.data &&
    err.response.data[0] &&
    err.response.data[0].message
  ) {
    msg = err.response.data[0].message;
  } else if (err.response && err.response.data && err.response.data.message) {
    msg = err.response.data.message;
  }
  return msg;
};
